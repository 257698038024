import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Backend_Url from '../../config/config';
// import jwtDecode from 'jwt-decode';
import { useCookies } from 'react-cookie';
import WTPSideBar from '../WhatsappCampaigns/WTPSideBar';
import MyContext from '../../context/Mycontext';
import Swal from 'sweetalert2';
import { jwtDecode } from 'jwt-decode';
import { Icon } from 'lucide-react';
import { BsDatabaseDown } from "react-icons/bs";
import { BsDatabaseCheck } from "react-icons/bs";
import { MdOutlineSync } from "react-icons/md";
import { IoCopy } from 'react-icons/io5';


const MyForm = ({ mobileslider, setmobileslider, openclosenav }) => {
    // Initialize state with default values
    const { setInstagramAccessToken } = useContext(MyContext);
    const [cookies] = useCookies(['token']);
    const token = cookies.token;
    // const [apiDropdown, setApiDropdown] = useState();
    const [apiSetData, setApiSetData] = useState([])
    const [hookURL, setHookURL] = useState('')
    const [compId, setCompId] = useState('')
    const [formData, setFormData] = useState({
        Name: '',
        ReservedField2: '',
        Url: '',
        Version: '',
        PhoneNumberId: '',
        AppId: '',
        Cnt_Cd: '',
        MobNo: '',
        IsDefault: true,
        RequestHeaderNameField1: '',
        RequestHeaderNameField2: '',
        RequestHeaderNameField3: '',
        RequestHeaderValueField1: '',
        RequestHeaderValueField2: '',
        RequestHeaderValueField3: '',
        RequestParameterNameField1: '',
        RequestParameterNameField2: '',
        RequestParameterNameField3: '',
        RequestParameterValueField1: '',
        RequestParameterValueField2: '',
        RequestParameterValueField3: '',
        WAAppId: '',
        InstaAppId: '',
        FaceBookAppID: '',
        MessengerAppId: '',
        WaAccessToken: '',
        InstaAccessToken: '',
        InstaMessageAccessToken: '',
        FacebookAccessToken: '',
        MessengerAccessToken: '',
        WhatsAppBusinessAccountId: '',
        ReservedField1: '',
        // ReservedField2: '',
        ReservedField3: '',
        ReservedField4: ''
    });

    // console.log(apiDropdown);

    const { apiDropdown, setApiDropdown } = useContext(MyContext);


    console.log('apiDropDown', apiDropdown);



    // Handle input change
    const handleChange = async (e) => {
        const { name, value, type, checked } = e.target;


        console.log(name, value, type, checked);

        // Handle number fields differently to ensure they are sent as numbers
        if (name === 'PhoneNumberId' || name === 'AppId' || name === 'Cnt_Cd' || name === 'MobNo') {
            setFormData({
                ...formData,
                [name]: value ? Number(value) : '' // Convert value to number if not empty
            });
        } else {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value
            });
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        debugger
        if (!token) {
            alert('User not authenticated');
            return;
        }
        const decodedToken = decodeJwt(token); // Use a JWT decoding library or function
        const usercode = decodedToken?.usercode;
        if (!usercode) {
            alert('User code not found in token');
            return;
        }
        const formDataWithUserCode = {
            ...formData,
            usercode
        };

        try {
            const response = await axios.put(`${Backend_Url}/whatsapp/v1/update/${apiDropdown}`, formDataWithUserCode,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
            if (response.status === 200) { // Check for successful creation
                alert("Updated Successfully");
                fetchDataByid(apiDropdown)
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            alert("Unable To Update");
        }

    }

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        debugger
        // Assuming you have the JWT token in local storage
        const jwtToken = cookies.token;
        if (!jwtToken) {
            alert('User not authenticated');
            return;
        }

        // Decode JWT token to extract claims
        const decodedToken = decodeJwt(jwtToken); // Use a JWT decoding library or function
        const usercode = decodedToken?.usercode;

        if (!usercode) {
            alert('User code not found in token');
            return;
        }

        // Add userCode to formData
        const formDataWithUserCode = {
            ...formData,
            usercode
        };

        try {
            const response = await axios.post(`${Backend_Url}/whatsapp/v1/create`, formDataWithUserCode,
                {
                    headers: {
                        Authorization: `Bearer ${jwtToken}`
                    }
                });
            if (response.status === 201) {
                alert("Created Successfully");
                fetchData()
                setFormData({
                    Name: '',
                    ReservedField2: '',
                    Url: '',
                    Version: '',
                    PhoneNumberId: '',
                    AppId: '',
                    Cnt_Cd: '',
                    MobNo: '',
                    IsDefault: true,
                    RequestHeaderNameField1: '',
                    RequestHeaderNameField2: '',
                    RequestHeaderNameField3: '',
                    RequestHeaderValueField1: '',
                    RequestHeaderValueField2: '',
                    RequestHeaderValueField3: '',
                    RequestParameterNameField1: '',
                    RequestParameterNameField2: '',
                    RequestParameterNameField3: '',
                    RequestParameterValueField1: '',
                    RequestParameterValueField2: '',
                    RequestParameterValueField3: '',
                    WAAppId: '',
                    InstaAppId: '',
                    FaceBookAppID: '',
                    MessengerAppId: '',
                    WaAccessToken: '',
                    InstaAccessToken: '',
                    FacebookAccessToken: '',
                    MessengerAccessToken: '',
                    WhatsAppBusinessAccountId: '',
                    ReservedField1: '',
                    // ReservedField2: '',
                    ReservedField3: '',
                    ReservedField4: ''
                });
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
            alert(error.response.data.message);
        }
    };

    // Function to decode JWT (you can use a library like jwt-decode)
    const decodeJwt = (token) => {
        try {
            const payload = token.split('.')[1];
            const decoded = atob(payload);
            return JSON.parse(decoded);
        } catch (e) {
            console.error('Error decoding JWT:', e);
            return null;
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/v1/whatsapp-api`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response) {
                // console.log(response.data);
                setApiSetData(response.data)
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, []);//get all method

    const fetchDataByid = async (id) => {
        try {
            const response = await axios.get(`${Backend_Url}/whatsapp/v1/getById/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response) {
                console.log(response.data);
                // setApiSetData(response.data)
                setFormData({
                    Name: response.data.data.Name,
                    Url: response.data.data.Url,
                    ReservedField2: response.data.data.ReservedField2,
                    Version: response.data.data.Version,
                    PhoneNumberId: response.data.data.PhoneNumberId,
                    AppId: response.data.data.AppId,
                    Cnt_Cd: response.data.data.Cnt_Cd,
                    MobNo: response.data.data.MobNo,
                    IsDefault: response.data.data.IsDefault,
                    RequestHeaderNameField1: response.data.data.RequestHeaderNameField1,
                    RequestHeaderNameField2: response.data.data.RequestHeaderNameField2,
                    RequestHeaderNameField3: response.data.data.RequestHeaderNameField3,
                    RequestHeaderValueField1: response.data.data.RequestHeaderValueField1,
                    RequestHeaderValueField2: response.data.data.RequestHeaderValueField2,
                    RequestHeaderValueField3: response.data.data.RequestHeaderValueField3,
                    RequestParameterNameField1: response.data.data.RequestParameterNameField1,
                    RequestParameterNameField2: response.data.data.RequestParameterNameField2,
                    RequestParameterNameField3: response.data.data.RequestParameterNameField3,
                    RequestParameterValueField1: response.data.data.RequestParameterValueField1,
                    RequestParameterValueField2: response.data.data.RequestParameterValueField2,
                    RequestParameterValueField3: response.data.data.RequestParameterValueField3,
                    WAAppId: response.data.data.WAAppId,
                    InstaAppId: response.data.data.InstaAppId,
                    FaceBookAppID: response.data.data.FaceBookAppID,
                    MessengerAppId: response.data.data.MessengerAppId,
                    WaAccessToken: response.data.data.WaAccessToken,
                    InstaAccessToken: (response.data.data.InstaAccessToken || ''),
                    InstaMessageAccessToken: response.data.data.ReservedField3,
                    FacebookAccessToken: response.data.data.FacebookAccessToken,
                    MessengerAccessToken: response.data.data.MessengerAccessToken,
                    WhatsAppBusinessAccountId: response.data.data.WhatsAppBusinessAccountId,
                    ReservedField1: response.data.data.ReservedField1,
                    // ReservedField2: response.data.data.ReservedField2,
                    ReservedField3: response.data.data.ReservedField3,
                    ReservedField4: response.data.data.ReservedField4
                });

                setInstagramAccessToken(response.data.data.InstaAccessToken || null);


            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (apiDropdown) {
            fetchDataByid(apiDropdown)
        }
    }, [apiDropdown]);


    const getURL = async (id) => {
        debugger
        if (!compId) {
            Swal.fire({
                title: 'Error!',
                text: 'Invalid Company Id!.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
        try {

            const response = await axios.get(`${Backend_Url}/whatsapp/v1/getURL/${id}`);
            if (response) {
                console.log(response)
                setHookURL(response.data.HookURL)
                handleURL(response.data.HookURL);
            }
            // Handle response data here
        } catch (error) {
            console.log(error);
        }

    };

    const handleURL = (hookURL) => {
        // alert("Hook URL: " + hookURL)
        debugger
        Swal.fire({
            title: 'Hook URL',
            text: hookURL,
            icon: 'success',
            confirmButtonText: 'Copy To Clipboard',
            background: '#f9f9f9',
            color: '#333',
            confirmButtonColor: '#3085d6',
            // footer: '<a href="#">Why do I see this?</a>'
            preConfirm: () => {

                return new Promise((resolve, reject) => {
                    const textArea = document.createElement('textarea');
                    textArea.value = hookURL;
                    document.body.appendChild(textArea);
                    textArea.select();
                    try {
                        document.execCommand('copy');
                        Swal.fire('Copied!', 'The URL has been copied to your clipboard.', 'success');
                    } catch (err) {
                        Swal.fire('Oops!', 'Something went wrong while copying the URL.', 'error');
                    } finally {
                        document.body.removeChild(textArea);
                    }
                });
            }
        });
    }

    const fetchCompDetails = async () => {
        const token = cookies.token;

        try {
            const decodedToken = jwtDecode(token);
            const companyCode = decodedToken.companyCode;
            const response = await axios.get(`${Backend_Url}/companydetails/getbycomp/${companyCode}`);
            if (response.data) {
                console.log(response);
                setCompId(response.data.id)
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchCompDetails()
    }, []);

    const SyncModel = async () => {
        const token = cookies.token;

        // Show loading indicator
        const swalLoading = Swal.fire({
            title: 'Syncing...',
            text: 'Please wait while we sync the models.',
            didOpen: () => {
                Swal.showLoading();
            },
            allowOutsideClick: false, // Prevent closing the alert
            showConfirmButton: false // Hide the confirm button
        });

        try {
            const response = await axios.post(`${Backend_Url}/helper/v1/sync-models`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            const { message, results } = response.data;

            // Close the loading alert
            swalLoading.close();

            // Show success message
            Swal.fire({
                title: 'Sync Status',
                text: message,
                icon: 'success',
                confirmButtonText: 'OK'
            });

            // Show errors for specific models if any
            results.forEach(result => {
                if (result.status === 'error') {
                    Swal.fire({
                        title: 'Sync Error',
                        text: `Model: ${result.model}\nMessage: ${result.message}`,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    });
                }
            });

        } catch (error) {
            // Close the loading alert
            swalLoading.close();

            // Handle error from API call
            console.error('Error during sync:', error);
            Swal.fire({
                title: 'Sync Failed',
                text: `Error: ${error.response ? error.response.data.error : error.message}`,
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const dbdownloader = async () => {
        try {
            debugger
            Swal.fire({
                title: 'Processing...',
                text: 'Please wait while we ready database file.',
                didOpen: () => {
                    Swal.showLoading();
                }
            });
            await axios.get(`${Backend_Url}/dbdownload`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                },
                responseType: 'blob'
            }).then((res) => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = "ProtectedDB.zip";
                a.click();
                window.URL.revokeObjectURL(url);
                // window.location.href = url;
                // window.open(url);
                Swal.fire({
                    title: 'Success',
                    text: 'Database download successfully!',
                    icon: 'success',
                    timer: 2000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    // willClose: () => {
                    //     
                    //     
                    // }
                });
            })
        } catch (error) {
            console.log(error)
        }
    }

    const restoredb = async (file) => {
        try {
            debugger
            console.log(file)
            Swal.fire({
                title: 'Processing...',
                text: 'Please wait while we are processing the DB.',
                didOpen: () => {
                    Swal.showLoading();
                },
                allowOutsideClick: false,
            })
            const formData = new FormData();
            formData.append('database', file)
            await axios.post(`${Backend_Url}/dbrestore`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + cookies.token,
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                if (res.status == 200) {
                    Swal.fire({
                        title: 'Db restored successfully',
                        icon: 'success',
                        confirmButtonText: 'Close'
                    })
                }
            })
        } catch (error) {
            console.log(error)
            Swal.fire({
                title: 'DB restore failed!',
                text: 'something went wrong',
                cancelButtonText: 'Try again'
            })
        }
    }

    const handlereplacedb = async (e) => {
        try {
            debugger
            let file;
            const fileselect = document.createElement('input')
            fileselect.type = 'file';
            fileselect.onchange = (e) => {
                file = e.target.files[0]
                fileselect.remove()
                restoredb(file)
            }
            fileselect.click(e)


        } catch (error) {
            console.log(error)
            Swal.fire({
                title: 'DB restore failed!',
                text: 'something went wrong',
                cancelButtonText: 'Try again'
            })
        }
    }

    return (
        <>
            <div className="Flex-Head-Right-Compaigns">

                <div>
                    <WTPSideBar mobileslider={mobileslider} setmobileslider={setmobileslider} />
                </div>
                <div className='flex flex-col justify-start items-center m-auto'>
                    {/* -------------Show settings----------------- */}
                    <div className={`w-full h-auto py-[20px] flex items-center flex-col justify-between`}>
                        {/* ----------showdbdownload----------- */}
                        <h3 className='font-bold text-[1.3rem] mb-[10px] mt-2'>DB Setting's</h3>
                        <div className={`w-full h-auto flex items-center justify-start`}>
                            <span onClick={() => dbdownloader()} className={`w-auto h-auto px-[20px] mr-[20px] flex cursor-pointer items-center bg-blue-400 justify-center text-[16px] font-[600] py-[20px] text-white rounded-md`}>
                                <BsDatabaseDown style={{ width: '23px', font: '500', height: 'auto', marginRight: '12px' }} /> DB download</span>
                            <span onClick={(e) => handlereplacedb(e)} className={`w-auto h-auto px-[20px] flex mr-[20px] items-center cursor-pointer bg-blue-400 justify-center text-[16px] font-[600] py-[20px] text-white rounded-md`}>
                                <BsDatabaseCheck style={{ width: '23px', font: '500', height: 'auto', marginRight: '12px' }} />Restore DB</span>
                            <span onClick={() => SyncModel()} className={`w-auto px-[20px] h-auto flex  mr-[20px] items-center bg-blue-400 cursor-pointer justify-center text-[16px] font-[600] py-[20px] text-white rounded-md`}>
                                <MdOutlineSync style={{ width: '23px', font: '500', height: 'auto', marginRight: '12px' }} />
                                DB Sync</span>

                            <span onClick={() => getURL(compId)} className={`w-auto px-[20px] h-auto flex  mr-[20px] items-center bg-blue-400 cursor-pointer justify-center text-[16px] font-[600] py-[20px] text-white rounded-md`}>
                                <IoCopy style={{ width: '23px', font: '500', height: 'auto', marginRight: '12px' }} />
                                Webhook URL</span>
                        </div>

                        {/* 
                        <div>
                            <h3 className='font-bold text-[1.3rem] mt-2'>Get Webhook URL</h3>
                            <button className="my-form__submit-btn !bg-green-600 mt-4" onClick={() => getURL(compId)}>Get URL</button>
                        </div> */}

                    </div>
                    <div>
                        <h3 className='font-bold text-[1.3rem] mt-2'>API Setting's</h3>
                    </div>
                    <form className="my-form lg:w-[964px] sm:w-[100%]  flex-col sm:grid  grid-cols-2 sm:grid-cols-1 gap-4 relative" onSubmit={handleSubmit}>
                        <div className="my-form__group">
                            <div className='flex justify-between w-full'>
                                <label className="my-form__label">Select From Avaliable Settings:</label>
                                <div className="flex items-center">
                                    <label className="my-form__label !m-0 !text-red-600">IsDefault:</label>
                                    <input
                                        className="my-form__checkbox"
                                        type="checkbox"
                                        name="IsDefault"
                                        checked={formData.IsDefault}
                                        onChange={handleChange}
                                    />
                                </div>

                            </div>
                        </div>

                        <div className='grid grid-cols-2 items-end sm:grid-cols-1  gap-2'>
                            <select
                                className="mb-1"
                                name="dropdown"
                                value={apiDropdown}
                                onChange={(e) => setApiDropdown(e.target.value)}
                            >
                                <option value="">Select an option</option>
                                {apiSetData && apiSetData.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.Name}
                                    </option>
                                ))}
                                {/* <option value="option1">Option 1</option>
                        <option value="option2">Option 2</option>
                        <option value="option3">Option 3</option> */}
                            </select>
                            <div className="my-form__group !w-full">
                                <label className="my-form__label">Name:</label>
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="Name"
                                    value={formData.Name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>



                        <div className='mb-3 mt-2'>
                            <label htmlFor="" className='text-[1.3rem] font-semibold text-green-500'>Whatsapp</label>
                        </div>
                        <div className='grid grid-cols-2 sm:grid-cols-1 gap-2 justify-center items-center pl-2'>
                            <div className="my-form__group">
                                <label className="my-form__label">WhatsApp Business Account Id:</label>
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="WhatsAppBusinessAccountId"
                                    value={formData.WhatsAppBusinessAccountId}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label">PhoneNumberId:</label>
                                <input
                                    className="my-form__input-number"
                                    type="number"
                                    name="PhoneNumberId"
                                    value={formData.PhoneNumberId}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label">AppId:</label>
                                <input
                                    className="my-form__input-number"
                                    type="number"
                                    name="AppId"
                                    value={formData.AppId}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label">WA App Id:</label>
                                <input
                                    className="my-form__input-number"
                                    type="number"
                                    name="WAAppId"
                                    value={formData.WAAppId}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label">Access Token:</label>
                                <input
                                    className="my-form__input-number"
                                    type="text"
                                    readOnly={true}
                                    name="WaAccessToken"
                                    value={formData.WaAccessToken}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>



                        <div className='!mb-3 mt-5'>
                            <label htmlFor="" className='text-[1.3rem] font-semibold text-pink-500'>Instagram</label>
                        </div>
                        <div className="grid grid-cols-2 gap-2 sm:grid-cols-1 pl-2">
                            <div className="my-form__group">
                                <label className="my-form__label">Insta App Id:</label>
                                <input
                                    className="my-form__input-number"
                                    type="number"
                                    name="InstaAppId"
                                    value={formData.InstaAppId}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="my-form__group ">
                                <label className="my-form__label">Access Token:</label>
                                <input
                                    className="my-form__input-number"
                                    type="text"
                                    readOnly={true}
                                    name="InstaAccessToken"
                                    value={formData.InstaAccessToken}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label">Access Token For Comments:</label>
                                <input
                                    className="my-form__input-number"
                                    type="text"
                                    // readOnly={true}
                                    name="InstaMessageAccessToken"
                                    value={formData.InstaMessageAccessToken}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="my-form__group">
                                <label className="my-form__label">Url:</label>
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="ReservedField2"
                                    value={formData.ReservedField2}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>


                        <div className='!mb-3 mt-5'>
                            <label htmlFor="" className='text-[1.3rem] font-semibold text-blue-500'>Facebook</label>
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-2">
                            <div className="my-form__group">
                                <label className="my-form__label">Facebook App Id:</label>
                                <input
                                    className="my-form__input-number"
                                    type="number"
                                    name="FaceBookAppID"
                                    value={formData.FaceBookAppID}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label">Access Token:</label>
                                <input
                                    className="my-form__input-number"
                                    type="text"
                                    readOnly={true}
                                    name="FacebookAccessToken"
                                    value={formData.FacebookAccessToken}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="my-form__group">
                                <label className="my-form__label">Url:</label>
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="Url"
                                    value={formData.Url}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label">Version:</label>
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="Version"
                                    value={formData.Version}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>


                        <div className='!mb-3 mt-5'>
                            <label htmlFor="" className='text-[1.3rem] font-semibold text-pink-700'>Messanger</label>
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-1 gap-2">
                            <div className="my-form__group">
                                <label className="my-form__label">Messenger App Id:</label>
                                <input
                                    className="my-form__input-number"
                                    type="number"
                                    name="MessengerAppId"
                                    value={formData.MessengerAppId}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label">Access Token:</label>
                                <input
                                    className="my-form__input-number"
                                    type="text"
                                    readOnly={true}
                                    name="MessengerAccessToken"
                                    value={formData.MessengerAccessToken}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label">Cnt_Cd:</label>
                                <input
                                    className="my-form__input-number"
                                    type="number"
                                    name="Cnt_Cd"
                                    value={formData.Cnt_Cd}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label">MobNo:</label>
                                <input
                                    className="my-form__input-number"
                                    type="number"
                                    name="MobNo"
                                    value={formData.MobNo}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        {/* <div className="my-form__group">
                    <label className="my-form__label">IsDefault:</label>
                    <input
                        className="my-form__checkbox"
                        type="checkbox"
                        name="IsDefault"
                        checked={formData.IsDefault}
                        onChange={handleChange}
                    />
                </div> */}

                        {/* Request Header Fields */}
                        <div className="grid grid-cols-2 sm:grid-cols-1 !mt-5 gap-2">
                            <div className="my-form__group">
                                <label className="text-[1.3rem] font-semibold mb-3">Request Header Name</label>
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestHeaderNameField1"
                                    value={formData.RequestHeaderNameField1}
                                    onChange={handleChange}
                                    placeholder='Header Name'
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label"> Value </label>
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestHeaderValueField1"
                                    value={formData.RequestHeaderValueField1}
                                    onChange={handleChange}
                                    placeholder='Header Value'
                                />
                            </div>
                            <div className="my-form__group">
                                {/* <label className="my-form__label">Request Header Name Field 2:</label> */}
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestHeaderNameField2"
                                    value={formData.RequestHeaderNameField2}
                                    onChange={handleChange}
                                    placeholder='Header Name'
                                />
                            </div>

                            <div className="my-form__group">
                                {/* <label className="my-form__label">Request Header Value Field 2:</label> */}
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestHeaderValueField2"
                                    value={formData.RequestHeaderValueField2}
                                    onChange={handleChange}
                                    placeholder='Header Value'
                                />
                            </div>

                            <div className="my-form__group">
                                {/* <label className="my-form__label">Request Header Name Field 3:</label> */}
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestHeaderNameField3"
                                    value={formData.RequestHeaderNameField3}
                                    onChange={handleChange}
                                    placeholder='Header Name'
                                />
                            </div>

                            <div className="my-form__group">
                                {/* <label className="my-form__label">Request Header Value Field 3:</label> */}
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestHeaderValueField3"
                                    value={formData.RequestHeaderValueField3}
                                    onChange={handleChange}
                                    placeholder='Header Value'
                                />
                            </div>
                        </div>


                        {/* Request Parameter Fields */}
                        <div className="grid grid-cols-2 sm:grid-cols-1 mt-5 gap-2">
                            <div className="my-form__group">
                                <label className="text-[1.3rem] font-semibold mb-3">Request Parameter </label>
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestParameterNameField1"
                                    value={formData.RequestParameterNameField1}
                                    onChange={handleChange}
                                    placeholder='Parameter Name'
                                />
                            </div>

                            <div className="my-form__group">
                                <label className="my-form__label">Value</label>
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestParameterValueField1"
                                    value={formData.RequestParameterValueField1}
                                    onChange={handleChange}
                                    placeholder='Parameter Value'
                                />
                            </div>
                            <div className="my-form__group">
                                {/* <label className="my-form__label">Request Parameter Name Field 2:</label> */}
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestParameterNameField2"
                                    value={formData.RequestParameterNameField2}
                                    onChange={handleChange}
                                    placeholder='Parameter Name'
                                />
                            </div>

                            <div className="my-form__group">
                                {/* <label className="my-form__label">Request Parameter Value Field 2:</label> */}
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestParameterValueField2"
                                    value={formData.RequestParameterValueField2}
                                    onChange={handleChange}
                                    placeholder='Parameter Value'

                                />
                            </div>
                            <div className="my-form__group">
                                {/* <label className="my-form__label">Request Parameter Name Field 3:</label> */}
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestParameterNameField3"
                                    value={formData.RequestParameterNameField3}
                                    onChange={handleChange}
                                    placeholder='Parameter Name'
                                />
                            </div>

                            <div className="my-form__group">
                                {/* <label className="my-form__label">Request Parameter Value Field 3:</label> */}
                                <input
                                    className="my-form__input-text"
                                    type="text"
                                    name="RequestParameterValueField3"
                                    value={formData.RequestParameterValueField3}
                                    onChange={handleChange}
                                    placeholder='Parameter Value'
                                />
                            </div>
                        </div>

                        {apiDropdown ? (<div className="my-form__button-container sticky bottom-0 left-0 flex items-center py-2">
                            <button className="my-form__submit-btn" onClick={handleUpdate}>Update</button>
                        </div>) : <div className="my-form__button-container sticky bottom-0 left-0 flex items-center">
                            <button className="my-form__submit-btn" type="submit">Submit</button>
                        </div>}


                    </form>


                </div>

            </div>

        </>
    );
};

export default MyForm;
