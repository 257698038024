import { configureStore } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { Template } from "../Component/flow/Template";
import { JSONCondition } from "../Component/flow/JSONCondition";
import { node } from "globals";

const initialState = {
  JSONData: null,
  tempNode: [],
  selectedNode: null,
};

const nodeSlice = createSlice({
  initialState,
  name: "nodeSlice",
  reducers: {
    convertToJSON: (state, action) => {
      const nodes = action.payload;
      console.log("nodes1", action.payload);
      //   let rawTemplate = JSON.parse(Template);
      //   rawTemplate = JSONCondition(nodes, rawTemplate);
      //   state.JSONData = JSON.stringify(rawTemplate);
    },

    selectNode: (state, action) => {
      state.selectedNode = action.payload;
    },
    setTempNode: (state, action) => {
      state.tempNode = action.payload;
    },
    exportToJSON: (state, action) => {
      // Convert JSON object to a string and encode it
      const nodes = action.payload;
      let rawTemplate = JSON.parse(Template);
      rawTemplate = JSONCondition(nodes, rawTemplate);
      state.JSONData = JSON.stringify(rawTemplate);
      const data = JSON.parse(state.JSONData);
      state.JSONData = JSON.parse(state.JSONData);

      let newFooter = {};
      let lastScreen = data.screens[data.screens.length - 1];
      let statelastscreen = state.JSONData.screens[data.screens.length - 1];
      // data.screens[0].layout.children.forEach((elm) => {
      //   debugger;
      //   if (elm.type === "Footer") {
      //     console.log("action", elm["on-click-action"]);
      //     let payload = {};

      //     data.screens[0].layout.children.forEach((e, index) => {
      //       if (e.type !== "Footer") {
      //         const name = e.name;
      //         payload = { ...payload, [name]: `$form.${name}` };
      //         // elm['on-click-action'].payload
      //       }
      //     });
      //     newFooter = {
      //       ...elm,
      //       "on-click-action": { ...elm["on-click-action"], payload },
      //     };
      //   }
      // });

      // lastScreen.layout.children.forEach((elm) => {
      //   if (elm.type === "Footer") {
      //     console.log("action", elm["on-click-action"]);
      //     let payload = {};

      //     // Loop through all elements except the footer
      //     data.screens.map((elm) => {
      //       elm.layout.children.forEach((e) => {
      //         if (e.type !== "Footer") {
      //           const name = e.name;
      //           payload = {
      //             ...payload,
      //             [name]: "$" + `{screen.${elm.id}.form.${name}}`,
      //           };
      //         }
      //       });
      //     });

      //     // Update the footer of the last screen
      //     newFooter = {
      //       ...elm,
      //       "on-click-action": { ...elm["on-click-action"], payload },
      //     };
      //   }
      // });

      lastScreen.layout.children.forEach((elm) => {
        debugger;
        if (elm.type === "Footer") {
          console.log("action", elm["on-click-action"]);
          let payload = {};

          // Loop through all screens
          data.screens.map((screen) => {
            screen.layout.children.forEach((element) => {
              // Skip the footer
              if (element.type !== "Footer") {
                const name = element.name;

                // Check if the element is from the last screen
                if (screen.id === lastScreen.id) {
                  // Add payload for last screen elements
                  payload = {
                    ...payload,
                    [name]: "$" + `{form.${name}}`,
                  };
                } else {
                  // Add payload for non-footer elements from other screens
                  payload = {
                    ...payload,
                    [name]: "$" + `{screen.${screen.id}.form.${name}}`,
                  };
                }
              }
            });
          });

          // Update the footer of the last screen
          newFooter = {
            ...elm,
            "on-click-action": { ...elm["on-click-action"], payload },
          };
        }
      });

      statelastscreen.layout.children = statelastscreen.layout.children.filter(
        (elm) => elm.type !== "Footer"
      );
      if (Object.keys(newFooter).length > 0) {
        statelastscreen.layout.children.push(newFooter);
      }
      console.log(state.JSONData);
      state.JSONData = JSON.stringify(state.JSONData);
      // const dataStr =
      //   "data:text/json;charset=utf-8," + encodeURIComponent(state.JSONData);

      // const downloadAnchor = document.createElement("a");
      // downloadAnchor.setAttribute("href", dataStr);
      // downloadAnchor.setAttribute("download", "data.json");

      // downloadAnchor.click();
    },
  },
});

export const store = configureStore({
  reducer: {
    nodeSlice: nodeSlice.reducer,
  },
});

export const nodeSliceAction = nodeSlice.actions;
