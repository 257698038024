import React, { useContext, useEffect, useState } from 'react'
import WTPSideBar from './WTPSideBar'
import "./CreateCompaignModal.css"
import { Icon } from '@iconify/react/dist/iconify.js'
import axios from 'axios'
import Backend_Url from '../../config/config'
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie'
import MyContext from '../../context/Mycontext';

import { FaFacebook } from "react-icons/fa";

import { LuLogOut } from "react-icons/lu";
import Loader from '../../Component/Loader/Loader'

import imageVideoIcon from "../../Assets/insta/imagevideo.png"

import EmojiPicker from 'emoji-picker-react'

const CreateCompaignModal = ({ closeWindow }) => {
    const [image, setimage] = useState(null)
    const [selectedimage, setselectedimage] = useState(null)
    const [caption, setcaption] = useState(null)
    const [cookies] = useCookies(['token']);
    const [APP_ID, setAPP_ID] = useState(null);

    const [postLoader, setPostLoader] = useState(false)
    const [logoutLoader, setLogoutLoader] = useState(false)

    const [isOpenEmojiPicker, setIsOpenEmojiPicker] = useState(false)


    const { instagramAccessToken, setInstagramAccessToken, apiDropdown, apiset } = useContext(MyContext)

    console.log('instagramAccessToken', instagramAccessToken)
    console.log('client ID', process.env.REACT_APP_INSTA_APP_ID)



    // --------------------------------onselectr ----------------------------------------------------
    const onSelectImage = async (e) => {
        try {
            debugger
            const file = e.target.files[0];
            setimage(file)
            if (file) {
                // Create a URL for the selected file
                const url = URL.createObjectURL(file);
                setselectedimage(url); // Set the image URL in state
            }
        } catch (error) {
            console.log(error);
        }
    };




    // ------------------------------handlesubmit--------------------------------------------
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            debugger
            setPostLoader(true);
            const formdata = new FormData();
            formdata.append("file", image)
            formdata.append("caption", caption)
            await axios.post(`${Backend_Url}/post/instaupload`, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${cookies.token}`
                }
            }).then((response) => {
                console.log(response.data)
                Swal.fire({
                    title: 'Success!',
                    text: 'Your post has been successfully submitted!',
                    icon: 'success',
                    confirmButtonText: 'Okay',
                });
                closeWindow(false);
            });
        } catch (error) {
            console.log(error);
            Swal.fire({
                title: 'Error!',
                text: 'There was an error submitting your post.',
                icon: 'error',
                confirmButtonText: 'Try Again',
            });
        } finally {
            setPostLoader(false)
        }
    }




    useEffect(() => {
        debugger
        if (apiset) {
            setAPP_ID(apiset.InstaAppId) //AppId
        }
    }, [apiset]);


    const handleLogout = async () => {
        setLogoutLoader(true);
        console.log('apiDropdown', apiDropdown);
        try {
            const response = await axios.put(`${Backend_Url}/whatsapp/deleteAccessToken/${apiDropdown}`, {}, {
                headers: {
                    'Authorization': `Bearer ${cookies.token}`
                }
            })

            if (response.status === 200) {

                Swal.fire({
                    title: 'Success!',
                    text: 'Logout Successfully!',
                    icon: 'success',
                    confirmButtonText: 'Okay',
                });
                setInstagramAccessToken(null);
                closeWindow(false);

            }
        } catch (error) {

        } finally {
            setLogoutLoader(false)
        }
    }

    return (
        <>
            {instagramAccessToken ?
                //     <div className="Flex-Head-Right-Compaigns ">
                //         {/* <div>
                //     <WTPSideBar />
                // </div> */}
                //         <div className={`container instacontainer`}>
                //             <form onSubmit={handleSubmit} className={`main-form sm:pl-[10px] sm:pt-[10px] sm:pb-[10px] sm:pr-[10px] sm:flex-col sm:!h-auto sm:w-full relative`}>
                //                 <div className={`form-left-side sm:!h-[130px] sm:!w-full`}>
                //                     {/* -----------------select_image-------------------- */}
                //                     {selectedimage == null ?
                //                         <div className={`selected-image w-full h-full bg-gray-300`}>
                //                             <p className='!bg-gray-300' style={{ position: 'absolute' }}>
                //                                 <Icon icon="ph:plus-fill" style={{ color: "black", width: '30px', height: '30px' }} />
                //                                 Select Image
                //                             </p>
                //                             <input type='file' style={{ opacity: '0', width: '100%', height: '100%', }} onChange={onSelectImage} placeholder='Select Image' />

                //                         </div>

                //                         : <img src={selectedimage} className={`selected-image`} alt="Selected" style={{ width: '100%', height: '100%', overflow: 'hidden', objectFit: 'cover' }} />
                //                     }

                //                     {
                //                         selectedimage != null &&
                //                         <button onClick={() => setselectedimage(null)} className={` w-auto h-auto absolute bottom-[15px] bg-gray-300  p-2 left-[15px]`}>
                //                             <Icon icon="mingcute:delete-fill" style={{ color: "black" }} />
                //                         </button>
                //                     }
                //                 </div>
                //                 <div className={`form-right-side sm:!w-[100%]  `}>
                //                     {/* -----------select_temp----------------- */}
                //                     {/* <div className={`div-for-form`}>
                //                 <label>Select Template</label>
                //                 <select>
                //                     <option>--select---</option>
                //                     <option>--select---</option>
                //                     <option>--select---</option>
                //                     <option>--select---</option>
                //                 </select>
                //             </div> */}
                //                     {/* -----------select gruop----------------- */}
                //                     {/* <div className={`div-for-form`}>
                //                 <label>Select Gruop</label>
                //                 <select>
                //                     <option>--select---</option>
                //                     <option>--select---</option>
                //                     <option>--select---</option>
                //                 </select>
                //             </div> */}
                //                     {/* ---------------------contacts----------------------- */}

                //                     <div className={`div-for-form sm:!p-0 sm:overflow-hidden`}>
                //                         <label className={`flex items-center justify-center gap-2 py-[10px]`}>Add caption <Icon icon="f7:captions-bubble" style={{ width: "15px", height: "15px", color: "black" }} /></label>
                //                         <textarea className={`!text-gray-800 sm:!p-0 sm:!pl-[4px] sm:pt-[4px] sm:!w-full !max-h-[100px] border border-spacing-1 border-gray-500`} onChange={(e) => setcaption(e.target.value)} style={{ padding: '10px' }} placeholder='write caption...'>
                //                         </textarea>
                //                     </div>
                //                     {/* ------------submit button------------------- */}
                //                     <div className={`button-div`} >
                //                         <button onClick={() => closeWindow(false)} className={`cancel`}>
                //                             Cancel
                //                         </button>
                //                         <button type='submit' className='w-20 h-8 flex items-center justify-center'>
                //                             {postLoader ? <Loader size={20} /> : 'Post'}
                //                         </button>
                //                     </div>
                //                     <div className='z-10 absolute bottom-4 right-[13%]'>
                //                         <label onClick={handleLogout} className='cursor-pointer flex justify-center items-center px-4 gap-1 py-1 !rounded-[5px] bg-[#2E498C] w-32 h-6 text-white  text-[.9rem]'>
                //                             {logoutLoader ? <Loader size={20} /> : <>
                //                                 <LuLogOut color='white' /> Logout
                //                             </>
                //                             }
                //                         </label>
                //                     </div>
                //                 </div>


                //             </form>

                //             <div onClick={() => closeWindow(false)} className={`backside sm:h-screen`}></div>
                //         </div>
                //     </div>


                <div className="Flex-Head-Right-Compaigns">
                    <div className={`container instacontainer`}>
                        <form onSubmit={handleSubmit} className={`main-form !w-[65%] overflow-hidden  sm:!w-[80%] md:!w-[70%] !h-[78%] !rounded-[15px] ${selectedimage ? '!gap-0 sm:!w-[95%] md:!w-[85%] max-lg:!w-[60%]' : ''} flex-col !justify-start !items-center`}>
                            <div className={`sm:!w-[100%] !w-full flex justify-center items-start `}>
                                <header className='py-2 !w-full text-center' style={{ borderBottom: '1px solid rgb(179, 177, 177)' }}>Create new post</header>
                            </div>

                            <div className='flex h-full justify-center items-center flex-col gap-4 w-full'>
                                {!selectedimage && <div>
                                    <img src={imageVideoIcon} alt="" className='size-[100px]' />
                                </div>}


                                {!selectedimage ?
                                    <div className=''>
                                        <label htmlFor='selectImage' className={`bg-[#0095f6] border-none px-4 py-[9px] rounded-[7px] cursor-pointer text-white font-semibold`}>Select from computer</label>
                                        <input type='file' className='hidden' id='selectImage' onChange={onSelectImage} placeholder='Select Image' />

                                    </div>

                                    :
                                    <div className='flex justify-center items-center !w-full h-full'>
                                        <img src={selectedimage} className={`selected-image z-10`} alt="Selected" style={{ width: '60%', height: '100%', overflow: 'hidden', objectFit: 'cover' }} />

                                        <div className='w-[40%] h-full' style={{ borderLeft: '1px solid rgb(179, 177, 177)' }}>
                                            <textarea name="" id="" value={caption} onChange={(e) => setcaption(e.target.value)} placeholder='Add a caption...' className='border-none text-[1rem] font-sans tracking-wide resize-none !w-[95%] h-[30%] outline-none px-3 py-2'></textarea>

                                            <div className='flex justify-between items-center mt-4 py-1 relative' style={{ borderBottom: '1px solid rgb(179, 177, 177)' }}>
                                                <label onClick={() => setIsOpenEmojiPicker(!isOpenEmojiPicker)} className='cursor-pointer ml-2'>??</label>
                                                <label htmlFor="" className='opacity-40 text-[.7rem]'>{caption?.length === 0 || !caption ? '0/2,200' : `${caption?.length}/2,200`}</label>
                                            </div>
                                            <div className=''>
                                                <EmojiPicker open={isOpenEmojiPicker} onEmojiClick={(e) => { setcaption(caption + e.emoji) }} className='z-50 absolute top-0 ' />
                                            </div>
                                            <div className='h-full flex justify-center items-center'>
                                                <button className={`bg-[#0095f6] h-[30px] w-[90%] border-none px-4 py-[9px] rounded-[7px] cursor-pointer text-white flex justify-center items-center font-semibold`}>{postLoader ? <Loader size={27} /> : 'Share'}</button>
                                            </div>
                                        </div>

                                    </div>
                                }
                            </div>


                        </form>
                        <div onClick={() => closeWindow(false)} className={`backside sm:h-screen`}></div>
                    </div>
                </div>


                :
                <div className="Flex-Head-Right-Compaigns">
                    <div className={`container instacontainer`}>
                        <div className={`main-form sm:pl-[10px] sm:pt-[10px] sm:pb-[10px] sm:pr-[10px] sm:flex-col sm:!h-auto sm:w-full relative !justify-center !items-center`}>
                            <div className={`sm:!w-[100%] !w-full flex justify-center items-center h-full `}>
                                <a className="mb-0 no-underline flex items-center gap-2 bg-[#1877f2] px-4 py-2 !rounded text-white font-semibold" href={`https://www.facebook.com/v21.0/dialog/oauth?client_id= ${APP_ID}&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=http://localhost:3000/dashboard/&response_type=token&scope=instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,pages_show_list,pages_read_engagement,instagram_manage_messages`}><FaFacebook />Login With Facebook</a>
                            </div>


                        </div>
                        <div onClick={() => closeWindow(false)} className={`backside sm:h-screen`}></div>
                    </div>
                </div>

            }
        </>

    )
}

export default CreateCompaignModal
