import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../../css/WTPSideBar.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import logo from "../../Assets/logo/logo.png";
import { useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';
import Backend_Url from '../../config/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import MyContext from '../../context/Mycontext';
import CreateCompaignModal from './CreateCompaignModal';



const WTPSideBar = ({ setmobileslider, openclosenav, mobileslider, isWhatsappWebPannel }) => {
    const [cookies] = useCookies(['token']);
    const [compId, setCompId] = useState("");
    const [hookURL, setHookURL] = useState();
    const [toggle, setToggle] = useState();
    const location = useLocation();
    // const [mobileslider, setmobileslider] = useState(false);
    console.log(compId)
    const { lstmessageData } = useContext(MyContext)




    const hasUnreadMessages = (messages) => {
        debugger
        // Check if any message has an unreadCount greater than 0
        return messages ? messages.some(message => message.unreadCount > 0) : null;
    };

    // Determine the state
    useEffect(() => {
        if (lstmessageData) {

            const state = hasUnreadMessages(lstmessageData);
            setToggle(state)

        }
    }, [lstmessageData])

    // console.log(state);

    const fetchCompDetails = async () => {
        const token = cookies.token;

        try {
            const decodedToken = jwtDecode(token);
            const companyCode = decodedToken.companyCode;
            const response = await axios.get(`${Backend_Url}/companydetails/getbycomp/${companyCode}`);
            if (response.data) {
                console.log(response);
                setCompId(response.data.id)
            }
        } catch (error) {
            console.error(error);
        }
    };


    // Get Webhook URL Code
    // const fetchData = async (id) => {
    //     try {

    //         const response = await axios.get(`${Backend_Url}/whatsapp/v1/getURL/${id}`);
    //         if (response) {
    //             console.log(response)
    //             setHookURL(response.data.HookURL)
    //         }
    //         // Handle response data here
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // useEffect(() => {
    //     fetchCompDetails()
    // }, []);


    // useEffect(() => {
    //     if (compId) {
    //         fetchData(compId);
    //     }
    // }, [compId]);

    // const handleURL = () => {
    //     // alert("Hook URL: " + hookURL)

    //     Swal.fire({
    //         title: 'Hook URL',
    //         text: hookURL,
    //         icon: 'success',
    //         confirmButtonText: 'Copy To Clipboard',
    //         background: '#f9f9f9',
    //         color: '#333',
    //         confirmButtonColor: '#3085d6',
    //         // footer: '<a href="#">Why do I see this?</a>'
    //         preConfirm: () => {

    //             return new Promise((resolve, reject) => {
    //                 const textArea = document.createElement('textarea');
    //                 textArea.value = hookURL;
    //                 document.body.appendChild(textArea);
    //                 textArea.select();
    //                 try {
    //                     document.execCommand('copy');
    //                     Swal.fire('Copied!', 'The URL has been copied to your clipboard.', 'success');
    //                 } catch (err) {
    //                     Swal.fire('Oops!', 'Something went wrong while copying the URL.', 'error');
    //                 } finally {
    //                     document.body.removeChild(textArea);
    //                 }
    //             });
    //         }
    //     });
    // }





    // ----------------------------modal open-----------------------------------
    const { modalopen, setmodalopen } = useContext(MyContext);
    const openCreateModal = () => {
        setmodalopen(true);
    }

    // useEffect(() => {
    //     setmobileslider(false)
    // }, [location.pathname]);

    useEffect(() => {
        console.log(setmobileslider, mobileslider)
    }, [mobileslider, setmobileslider]);


    console.log('isWhatsappWebPannel', isWhatsappWebPannel)

    console.log('pathName', location.pathname);

    // const dbdownloader = async ()=>{
    //     try {
    //         debugger
    //         await axios.get(`${Backend_Url}/dbdownload`,{
    //             headers: {
    //                 Authorization: `Bearer ${cookies.token}`
    //             },
    //             responseType: 'blob'
    //         }).then((res)=>{
    //             console.log(res)
    //             const url = window.URL.createObjectURL(new Blob([res.data]));
    //             const a = document.createElement('a');
    //             a.href = url;
    //             a.download = "Database.db";
    //             a.click();
    //             window.URL.revokeObjectURL(url);
    //             // window.location.href = url;
    //             // window.open(url);
    //         })
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    return (
        <>
            <div className={`sidebar-WTP ${isWhatsappWebPannel && '!hidden'}`}>
                <div className="Flex-logo-sidebar">
                    <img className="Logo-Img-size" src={logo} alt='' />
                    <h2 className='font-bold text-[1.3rem]'>WaTechcherry</h2>
                </div>
                <nav>
                    <ul>
                        <li>
                            <NavLink exact to="/dashboard" style={{ color: location.pathname == '/dashboard' ? '#007bff' : 'black', fontWeight: '600' }} activeClassName="active">
                                <Icon icon="bxs:dashboard" className="sidebar-WTP-icon" />
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink exact to="/WTPCampaigns" activeClassName="active">
                                <Icon icon="simple-icons:campaignmonitor" className="sidebar-WTP-icon" />
                                Campaigns
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/WTPContacts" activeClassName="active">
                                <Icon icon="gravity-ui:persons-lock" className="sidebar-WTP-icon" /> Contacts
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/whatsappweb" activeClassName="active">

                                <Icon icon="solar:inbox-in-broken" className="sidebar-WTP-icon" />

                                <div className={`inbox-with-point`}>
                                    Inbox
                                    {toggle && <span><Icon icon="tabler:point-filled" style={{ color: "#54ff47" }} /></span>}
                                </div>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/templates" activeClassName="active">
                                <Icon icon="eos-icons:templates-outlined" className="sidebar-WTP-icon" />Local Templates
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to="/metatemplates" style={{ color: location.pathname == '/createcustomtemplate' || location.pathname == '/metatemplates' ? '#007bff' : 'black', fontWeight: '600' }} activeClassName="active">
                                <Icon icon="tabler:template" className="sidebar-WTP-icon" /> Meta Templates
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink
                                to="/dummy-route"
                                activeClassName="active"
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent navigation
                                    handleURL();
                                }}
                            >
                                <Icon icon="carbon:url" className="sidebar-WTP-icon" /> Get URL
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink
                                to="/WhatsappAutomationAndChatBot"
                                activeClassName="active"
                            >
                                <Icon icon="material-symbols:automation" className="sidebar-WTP-icon" /> Automation
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/Whatsappapi" activeClassName="active">
                                <Icon icon="lets-icons:setting-alt-fill" className="sidebar-WTP-icon" /> Setting
                            </NavLink>
                        </li>
                        {/* <li>

                        <NavLink to="/WhatsappWeb" activeClassName="active">
                            <Icon icon="eos-icons:templates-outlined" className="sidebar-WTP-icon" /> WhatsApp Web
                        </NavLink>
                    </li> */}
                        <li>
                            <NavLink to="/Integration" activeClassName="active">
                                <Icon icon="fluent:person-accounts-24-regular" className="sidebar-WTP-icon" />
                                Integration
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={openCreateModal} style={{ color: '#333', fontWeight: '600' }} activeClassName="active">
                                <Icon icon="basil:instagram-solid" className="sidebar-WTP-icon" style={{ color: "black" }} />Instagram Post
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink
                                to="/dummy-route"
                                activeClassName="active"
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent navigation
                                    // SyncModel();
                                }}
                            >
                                <Icon icon="ic:round-sync" className="sidebar-WTP-icon" /> Sync Database
                            </NavLink>
                        </li> */}

                        {/* -------------download-db--------------- */}
                        {/* <li>
                            <NavLink
                                to="/dummy-route"
                                activeClassName="active"
                                onClick={(e) => {
                                    e.preventDefault();  
                                    // dbdownloader()
                                }}
                            >
                                <Icon icon="dashicons:database-view" className="sidebar-WTP-icon" /> DB Download
                            </NavLink>
                        </li> */}
                    </ul>
                </nav>
                {
                    // -----------------------MOBILE---------------------------------

                }
                {/* <div style={{ display: `${!mobileslider ? '!block' : '!none'}` }} className="sidebar-WTP-MOBILE">
                    <div className="Flex-logo-sidebar sm:flex sm:items-center sm:justify-between">
                        <img className="Logo-Img-size" src={logo} alt='' />
                        <h2 className={`sm:text-[19px]`}>WaTechcherry</h2>

                        <button className={`sm:w-auto  hidden sm:flex sm:items-center sm:justify-center sm:h-auto sm:m-0 sm:bg-transparent sm:text-xl sm:border-none sm:p-[9px]`} onClick={() => setmobileslider(false)}>
                            <Icon icon="radix-icons:cross-2" style={{ color: "black" }} />
                        </button>
                    </div>
                    <nav>
                        <ul>
                            <li>
                                <NavLink onClick={() => setmobileslider(false)} exact to="/dashboard" style={{ color: location.pathname === '/dashboard' ? '#007bff' : 'black', fontWeight: '600' }} activeClassName="active">
                                    <Icon icon="bxs:dashboard" className="sidebar-WTP-icon" />
                                    Dashboard
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/dummy-route"
                                    activeClassName="active"
                                    onClick={(e) => {
                                        e.preventDefault(); // Prevent navigation
                                        SyncModel();
                                        setmobileslider(false);
                                    }}
                                >
                                    <Icon icon="ic:round-sync" className="sidebar-WTP-icon" /> Sync Database
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>  */}
            </div>


            <div className={`sidebar-WTP-MOBILE lg:hidden sm:block ${mobileslider ? '!right-[0px]' : '!right-[-263px]'}`}>
                <div className="Flex-logo-sidebar sm:flex sm:items-center sm:justify-between">
                    <img className="Logo-Img-size" src={logo} alt='' />
                    <h2 className={`sm:text-[19px] font-bold text-[1.3rem]`}>WaTechcherry</h2>

                    <button className={`sm:w-auto  hidden sm:flex sm:items-center sm:justify-center sm:h-auto sm:m-0 sm:bg-transparent sm:text-xl sm:border-none sm:p-[9px]`} onClick={() => setmobileslider(false)}>
                        <Icon icon="radix-icons:cross-2" style={{ color: "black" }} />
                    </button>
                </div>
                <nav>
                    <ul>
                        <li>
                            <NavLink onClick={() => setmobileslider(false)} exact to="/dashboard" style={{ color: location.pathname == '/dashboard' ? '#007bff' : 'black', fontWeight: '600' }} activeClassName="active">
                                <Icon icon="bxs:dashboard" className="sidebar-WTP-icon" />
                                Dashboard
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={() => setmobileslider(false)} exact to="/WTPCampaigns" activeClassName="active">
                                <Icon icon="simple-icons:campaignmonitor" className="sidebar-WTP-icon" />
                                Campaigns
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={() => setmobileslider(false)} to="/WTPContacts" activeClassName="active">
                                <Icon icon="gravity-ui:persons-lock" className="sidebar-WTP-icon" /> Contacts
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={() => setmobileslider(false)} to="/whatsappweb" activeClassName="active">

                                <Icon icon="solar:inbox-in-broken" className="sidebar-WTP-icon" />

                                <div className={`inbox-with-point`}>
                                    Inbox
                                    {toggle && <span><Icon icon="tabler:point-filled" style={{ color: "#54ff47" }} /></span>}
                                </div>
                            </NavLink>
                        </li>
                        {/* <li>
                                <NavLink onClick={() => setmobileslider(false)} to="/templates" activeClassName="active">
                                    <Icon icon="eos-icons:templates-outlined" className="sidebar-WTP-icon" />Local Templates
                                </NavLink>
                            </li> */}
                        <li>
                            <NavLink onClick={() => setmobileslider(false)} to="/metatemplates" activeClassName="active" style={{ color: location.pathname == '/createcustomtemplate' ? '#007bff' : 'black', fontWeight: '600' }}>
                                <Icon icon="tabler:template" className="sidebar-WTP-icon" /> Meta Templates
                            </NavLink>
                        </li>
                        {/* <li>
                                <NavLink
                                    to="/dummy-route"
                                    activeClassName="active"
                                    onClick={(e) => {
                                        e.preventDefault(); // Prevent navigation
                                        handleURL();
                                        setmobileslider(false)
                                    }}
                                >
                                    <Icon icon="carbon:url" className="sidebar-WTP-icon" /> Get URL
                                </NavLink>
                            </li> */}
                        <li>

                            <NavLink onClick={() => setmobileslider(false)} to="/Whatsappapi" activeClassName="active">
                                <Icon icon="lets-icons:setting-alt-fill" className="sidebar-WTP-icon" />  Setting
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={() => setmobileslider(false)} to="/WTPBusinessAccount" activeClassName="active">
                                <Icon icon="fluent:person-accounts-24-regular" className="sidebar-WTP-icon" /> WABA Status
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={() => {
                                openCreateModal();
                                setmobileslider(false)
                            }} style={{ color: '#333', fontWeight: '600' }} activeClassName="active">
                                <Icon icon="basil:instagram-solid" className="sidebar-WTP-icon" style={{ color: "black" }} />Instagram Post
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink
                                to="/dummy-route"
                                activeClassName="active"
                                onClick={(e) => {
                                    e.preventDefault(); // Prevent navigation
                                    SyncModel();
                                    setmobileslider(false)
                                }}
                            >
                                <Icon icon="ic:round-sync" className="sidebar-WTP-icon" /> Sync Database
                            </NavLink>
                        </li> */}
                    </ul>
                </nav>
            </div >
        </>
    )
}

export default WTPSideBar
