import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import Backend_Url from '../../config/config'
import { useCookies } from 'react-cookie';
import { Icon } from '@iconify/react/dist/iconify.js';
import MyContext from '../../context/Mycontext';

const Report = (props) => {
    const [cookies] = useCookies(['token']);
    const [campaigndata, setcampaigndata] = useState([]);
    const [successfullmsg, setSuccessfullmsg] = useState([])
    const [failedMsg, setFailedMsg] = useState([]);
    const { message, setmessage } = useContext(MyContext)

    useEffect(() => {
        console.log("campaigndata", campaigndata);
        console.log("failedMsg", failedMsg);
        console.log("successfullmsg", successfullmsg);
    }, [failedMsg, campaigndata, successfullmsg]);


    const getdata = async () => {
        debugger
        try {
            await axios.get(`${Backend_Url}/messagesstatus/findByid/${props.id.Campaing_ID}`, {
                headers: {
                    'Authorization': `Bearer ${cookies.token}`
                }
            }).then((res) => {
                console.log(res.data)
                setcampaigndata(res.data.data)
                setSuccessfullmsg(res.data.successfullmessage)
                const failed = res.data.successfullmessage.filter(itm => itm.TemplateStatus === 'failed')
                setFailedMsg(failed)
            })
        } catch (error) {
            console.log(error)
        }
    }

    const modaldata = [
        {
            count: campaigndata.length,
            label: 'Total Number',
            icon: <Icon icon="ion:people-sharp" style={{ color: "black" }} />
        },

        {
            count: failedMsg.length,
            label: 'Total Failed',
            icon: <Icon icon="ion:people-sharp" style={{ color: "black" }} />
        },
        {
            count: successfullmsg.length - failedMsg.length,
            label: 'Total Success',
            icon: <Icon icon="ion:people-sharp" style={{ color: "black" }} />
        },
        // {
        //     count: ,
        //     label: 'Total Send',
        //     icon: <Icon icon="ion:people-sharp" style={{ color: "black" }} />
        // }
    ]

    // ----------------------GetmsgText----------------------------
    const GetmsgText = () => {
        try {

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        console.log(campaigndata);
    }, [campaigndata]);

    useEffect(() => {
        getdata()
    }, []);
    return (
        <>
            <div className={`flex w-full h-full z-[910] items-center justify-center fixed top-0 left-0`}>
                <div className={`w-[70%] z-[920] h-auto overflow-hidden bg-white rounded-md`}>
                    <div className={`flex items-center bg-[#f8f9fa] justify-start gap-2 py-[10px] pl-[15px]  w-[98%]`}>
                        <div className={` h-[25px] w-[3px] bg-green-400`} />
                        <p className={` text-[17px] font-bold !m-0`}>Campaign Details</p>
                        {/* <div className={`w-[35px] h-[35px] rounded-full overflow-hidden`}>
                                    <span className={`w-full  h-full rounded-full flex items-center justify-center bg-[#1dc31d]`}>
                                        <Icon icon="fa:hashtag" style={{ color: "white" }} />
                                    </span>
                                </div> */}
                    </div>

                    <div className={`flex items-center bg-[#f8f9fa] justify-center flex-col h-auto py-[20px] w-full`}>
                        {/* <div className={`w-full h-auto flex items-start justify-center flex-col`}>
                            <div className={`w-[90%] px-[10px] gap-2 flex items-center justify-start`}>
                                <h2 className={` font-[500] text-[18px] m-0`}>Campaign Name:</h2>
                                <p className={`text-[15px] font-[400] m-0`}>{props.id.Campaing_Name}</p>
                            </div>
                            <div className={`w-full px-[10px] gap-2 flex items-center justify-start`}>
                                <h3 className={` font-[500] m-0`}>Send Date:</h3>
                                <p className={`text-[15px] font-[400] m-0`}>{props.id.Date}</p>
                            </div>
                            <div className={`w-full px-[10px] gap-2 flex items-center justify-start`}>
                                <h3 className={` font-[500] m-0`}>Owner Name:</h3>
                                <p className={`text-[15px] font-[400] m-0`}>{props.id.Owner_Name}</p>
                            </div>
                        </div> */}

                        {/* -------------------show data------------------------------ */}
                        <div className={`w-full h-auto flex items-center sm:flex-wrap sm:gap-2 justify-around flex-row py-[20px]`}>
                            {
                                modaldata.map((item, index) => {
                                    return (
                                        <>
                                            <section className={`w-[22%] gap-2 sm:w-[90%] h-auto min-h-[50px] ${index == 1 && 'bg-[#d9d9d9]'} ${index == 0 && 'bg-pink-100'} ${index == 2 && 'bg-green-100'} ${index == 3 && 'bg-yellow-100'} py-[20px] flex items-center justify-start rounded-[5px]`}>
                                                <div className={`w-[25%] ml-[10px] rounded-full py-[10px] m-0 sm:py-[5px] sm:hidden  bg-white text-[15px] flex items-center justify-center h-auto`}>
                                                    {item.icon}
                                                </div>
                                                <div className={`w-[80%] sm:pl-[10px] h-[50px] flex items-start justify-center flex-col`}>
                                                    <h2 className={`w-full m-0 h-auto text-[18px] font-[500]`}>{item.count}</h2>
                                                    <p className={`text-[15px] sm:text-[13px] m-0 font-[500]`}>{item.label}</p>
                                                </div>


                                            </section>
                                        </>
                                    )
                                })
                            }
                        </div>

                        {/* -------------------------message-------------------------------- */}
                        <div className={`w-[90%] py-[15px] px-[20px] flex items-start justify-center flex-col`}>
                            <h2 className={`font-[500] text-[15px] m-0 mb-[10px]`}>Campaign message</h2>
                            <p className={`bg-gray-200 w-full sm:w-[90%] h-auto m-0 py-[10px] px-[10px]   rounded-md `}>
                                {props.id.Wts_msg}
                            </p>
                        </div>

                        {/* ----------------number--------------- */}
             
             
                    </div>
                </div>
                <div onClick={() => props?.setmodalreport(false)} className={`w-full bg-[#00000047] h-full absolute top-0 left-0`}></div>
            </div>


        </>
    )
}

export default Report