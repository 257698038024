import React, { useContext, useEffect, useState } from 'react'
import "./Dashboard.css"
import WTPSideBar from '../WhatsappCampaigns/WTPSideBar'
import { Icon } from '@iconify/react/dist/iconify.js'
import axios, { all } from 'axios'
import Backend_Url from '../../config/config'
import { useCookies } from 'react-cookie'
import MyContext from '../../context/Mycontext'
import CreateCompaignModal from '../WhatsappCampaigns/CreateCompaignModal'
import Swal from 'sweetalert2'
import Report from './Report'

const Dashboard = ({ mobileslider, setmobileslider, openclosenav }) => {
    const [cookies] = useCookies(['token']);
    const [totalContact, settotalContact] = useState(0);
    const [deliverdCount, setdeliverdCount] = useState(0);
    const [successfullyaccepted, setsuccessfullyaccepted] = useState([]);
    const [successfullmsg, setsuccessfullmsg] = useState([]);
    const [totalfailedmsg, settotalfailedmsg] = useState([]);
    const [totalsendmsg, settotalsendmsg] = useState([]);
    const [totaldelivery, settotaldelivery] = useState([]);
    const { messagedeliverd, setmessagedeliverd } = useContext(MyContext)
    const [] = useState(null);

    useEffect(() => {
        console.log("successfullyaccepted", successfullyaccepted);
        console.log("successfullmsg", successfullmsg);
        console.log("totalfailedmsg", totalfailedmsg);
        console.log("totalsendmsg", totalsendmsg);
    }, [totalsendmsg, successfullyaccepted, successfullmsg, totalfailedmsg]);


    const { instagramAccessToken, setInstagramAccessToken, apiDropdown, apiset } = useContext(MyContext);



    // useEffect(()=>{

    //     const getDefault = async() => {
    //         let res = await axios.get(`${Backend_Url}/whatsapp/getDefault`, {
    //             headers: {
    //                 'Authorization': `Bearer ${cookies.token}`
    //             }
    //         })
    //         console.log('default ', res);
    //         if(res?.status === 200){
    //             setDefaultId(res.data.id)
    //             setInstagramAccessToken(res.data.InstaAccessToken);
    //         }
    //     }

    //     getDefault();
    // }, [])


    const getLongLiveAccessToken = async (token) => {
        debugger
        const fbExchangeToken = `${token}`; // Replace with the short-lived access token

        const url = `https://graph.facebook.com/v20.0/oauth/access_token?` +
            `grant_type=fb_exchange_token&` +
            `client_id=${apiset.InstaAppId}&` + //AppId
            `client_secret=${apiset.ReservedField4}&` +
            `fb_exchange_token=${fbExchangeToken}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
            });


            if (!response.ok) {
                throw new Error('Failed to fetch access token');
            }

            const data = await response.json();

            if (data?.access_token) {



                console.log('apiDropDown', apiDropdown)
                console.log('access_token: ' + data.access_token);
                // get Id of isDefault
                // debugger
                // let res = await axios.get(`${Backend_Url}/whatsapp/getDefault`, {
                //     headers: {
                //         'Authorization': `Bearer ${cookies.token}`
                //     }
                // })
                // save accessToken in Db 
                const resp = await axios.put(`${Backend_Url}/whatsapp/updateAccessToken/${apiDropdown}`, { InstaAccessToken: data.access_token }, {
                    headers: {
                        'Authorization': `Bearer ${cookies.token}`
                    }
                })
                if (resp.status === 200) {
                    setInstagramAccessToken(data.access_token);
                }

                console.log(resp);
            }


            //   console.log('response access token : ', data);
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    }


    useEffect(() => {
        debugger
        console.log(instagramAccessToken)
        if (!instagramAccessToken) {
            // Get the URL fragment (after the #)
            const urlFragment = window.location.hash;
            // Create URLSearchParams from the fragment (remove the leading '#')
            const params = new URLSearchParams(urlFragment.slice(1));
            // Get the access_token from the parameters
            let shortLiveAccessToken = params.get('access_token');
            // const access_token = localStorage.getItem('access_token');
            if (shortLiveAccessToken && apiset) {
                //   dispatch(tokenSliceAction.setAccessToken(access_token))
                // console.log('access_token', shortLiveAccessToken);
                getLongLiveAccessToken(shortLiveAccessToken)
            } else {

            }
        }
    }, [apiDropdown, apiset]);




    const data = [
        {
            count: totalContact,
            label: 'Total Contact',
            icon: (
                <>
                    <Icon icon="ic:round-people" style={{ color: "black" }} />
                </>
            )

        },
        {
            count: successfullyaccepted.length,
            label: 'Total Campaigns',
            icon: (
                <Icon icon="ic:round-people" style={{ color: "black" }} />
            )
        },
        {
            count: successfullmsg.length,
            label: 'Success Campaigns',
            icon: (
                <Icon icon="ic:round-people" style={{ color: "black" }} />
            )
        },
        {
            count: totalfailedmsg.length,
            label: 'Failed messages',
            icon: (
                <Icon icon="ic:round-people" style={{ color: "black" }} />
            )
        }
    ]

    const otherappid = [
        {
            appname: 'instagram',
            icon: <Icon icon="ri:instagram-fill" style={{ color: "white" }} />
        },
        {
            appname: 'Whatsapp',
            icon: <Icon icon="simple-icons:whatsapp" style={{ color: "white" }} />
        },
        {
            appname: 'messenger',
            icon: <Icon icon="fe:messanger" style={{ color: "white" }} />
        },
    ]

    function convertDateToDDMMYYYY(isoDate) {
        const date = new Date(isoDate); // Create a Date object from the ISO string

        // Get day, month, and year
        const day = String(date.getUTCDate()).padStart(2, '0'); // Get day and pad with leading zero
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Get month (0-indexed) and pad
        const year = date.getUTCFullYear(); // Get full year

        // Return formatted date in dd/mm/yyyy
        return `${day}/${month}/${year}`;
    }

    const tabeldata = totalsendmsg.map((item) => {
        // console.log(item)
        return ({
            Date: convertDateToDDMMYYYY(item.created_at),
            Campaing_ID: item.campaignId,
            Campaing_Name: item.campaign_name,
            Owner_Name: item.campaignOwner,
            Template_Name: item.templateName,
            Status: item.wa_message_accept,
            Contacts: item.contact_number,
            Sent: successfullyaccepted.length,
            ID: item.id,
            Report: 'View',
            Wts_msg: item.wa_message_text,
            TemplateStatus: item.TemplateStatus
        })
    })




    // --------------------------------fetch data --------------------------------
    const fetallcontact = async (e) => {
        try {
            // debugger
            await axios.get(`${Backend_Url}/contacts/contacts`, {
                headers: {
                    'Authorization': `Bearer ${cookies.token}`
                }
            }).then((res) => {
                console.log(res)
                settotalContact(res.data.length)
            })
        } catch (error) {
            console.log(error)
        }
    }


    // ---------------------------fetch accepted count -----------------------------

    const fetchaccepted = async () => {
        debugger
        try {
            // debugger
            await axios.get(`${Backend_Url}/messagesstatus/compaing/all`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            }).then((res) => {
                console.log(res.data)
                // const responsedata = res.data.data
                setsuccessfullyaccepted(res.data.data.filter((item) => item.wa_message_accept == 'accepted'))
                settotalfailedmsg(res.data.data.filter((item) => item.TemplateStatus === 'failed'))
                setsuccessfullmsg(res.data.data.filter((item) => item.TemplateStatus !== 'failed'))
            });

        } catch (error) {
            console.log(error)
        }
    }

    const fetchtotal = async (e) => {
        try {
            await axios.get(`${Backend_Url}/messagesstatus/compaing/all`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            }).then((res) => {
                console.log(res.data)
                // const responsedata = res.data.data
                settotalsendmsg(res.data.data)
            });

        } catch (error) {
            console.log(error)
        }
    }


    const [modalforreport, setmodalreport] = useState(false);
    const [selectedcampaign, setselectedcampaign] = useState(0);
    const [reportdata, setreportdata] = useState(0)
    const openModalforreport = (wa_item) => {
        // debugger
        console.log(wa_item)
        setselectedcampaign(wa_item.id)
        setreportdata(wa_item)
        setmodalreport(!modalforreport)
    }

    // --------------------------fetch msg-------------------------------------
    const [msgtext, setmsgtext] = useState(null)
    const fetchmessage = async () => {
        try {
            // debugger
            await axios.get(`http://localhost:5000/message/getbyid/${reportdata}`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            }).then((res) => {
                console.log(res)
                // setmsgtext(res.data.data.text.body);
            })
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchmessage();
    }, [reportdata]);

    const [messagedata, setmessagedata] = useState('hello world');


    const [openinsta, setopeninsta] = useState(false);
    const openinstamodal = () => {
        setopeninsta(!openinsta)
    }


    // ----------------------------------delete handler----------------------------------------------------
    const deletehandler = async (id) => {
        try {
            // debugger
            await axios.delete(`${Backend_Url}/messagesstatus/${id}`, {
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            }).then((res) => {
                console.log(res);
                Swal.fire(
                    `Delete Successfully`,
                )
                fetchtotal()
            })
        } catch (error) {
            Swal.fire(
                'Error',
                `Delete failed.`,
            )
            console.log(error)
        }
    }

    // --------------------------------retrive deliverd count ---------------------
    useEffect(() => {
        fetallcontact()
        fetchaccepted()
        fetchtotal()
        console.log("messagedeliverd", messagedeliverd);
    }, [])


    const handlecampaignfill = (campid) => {
        // debugger
        fetchaccepted(campid)
    }

    const HandleCampaingdelete = async () => {
        try {
            // debugger
            console.log(totalsendmsg)
            const allid = [totalsendmsg.map((item) => item.id,)]
            console.log(allid[0])

            await axios.delete(`${Backend_Url}/whatsapp/v1/campaignorquery/`, {
                data: {
                    ids: allid[0],
                },
                headers: {
                    Authorization: `Bearer ${cookies.token}`
                }
            })


        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        console.log(totalContact);
    }, [totalContact]);
    return (
        <>
            <div className="Flex-Head-Right-Compaigns sm:h-fit">
                <div>
                    <WTPSideBar mobileslider={mobileslider} setmobileslider={setmobileslider} />
                </div>
                <div className="Right-Campaigns-Body h-full">
                    {/* -------------top-------------------- */}
                    <div className={` w-full flex items-center justify-start flex-col`}>
                        <div className={`flex items-center bg-[#f8f9fa] justify-between py-[10px] px-[15px] w-full`}>
                            <p className={` text-[17px] pl-[15px] font-bold`}>Dashboard</p>
                            <div className={`w-[35px] h-[35px] rounded-full overflow-hidden`}>
                                {/* <span className={`w-full  h-full rounded-full flex items-center justify-center bg-[#1dc31d]`}>
                                    <Icon icon="fa:hashtag" style={{ color: "white" }} />
                                </span> */}
                            </div>
                        </div>
                        <div className={`w-full h-auto sm:flex-row sm:gap-3 flex sm:flex-wrap items-center justify-around bg-[#f8f9fa] py-[15px] sm:!grid sm:!grid-cols-2 sm:px-4`}>
                            {
                                data.map((item, index) => {
                                    return (
                                        <>
                                            <section className={`w-[22%] sm:w-[100%] sm:flex-col  sm:h-[100px] min-h-[50px] ${index == 1 && 'bg-[#d9d9d9]'} ${index == 0 && 'bg-pink-100'} ${index == 2 && 'bg-green-100'} ${index == 3 && 'bg-yellow-100'} py-[20px] flex items-center justify-start rounded-[5px] sm:!py-2 sm:!h-auto sm:gap-2`}>
                                                <div className={`w-[25%]  text-3xl sm:w-auto sm:text-4xl flex items-center justify-center h-full`}>
                                                    {item.icon}
                                                </div>
                                                <div className={`w-[70%] sm:w-auto  h-[50px] sm:items-center sm:justify-start sm:text-left sm:gap-0  -spacing-1 -black flex items-start justify-center flex-col  `}>
                                                    <h2 className={`w-full sm:text-[20px] h-auto sm:w-auto text-[25px] m-0 font-[700]`}>{item.count}</h2>
                                                    <p className={`text-[15px] sm:flex sm:items-center sm:justify-start sm:w-auto sm:text-[13px] font-[500] m-0`}>{item.label}</p>
                                                </div>
                                            </section>
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>

                    {/* -----------------------other app id--------------------------------------------------- */}
                    <div className={`w-full min-h-[50px] flex items-center bg-[#dfdfdf] justify-start gap-[40px] sm:min-h-[30px] sm:w-[90%] sm:gap-[10px] sm:mx-[10px] sm:mr-[10px] sm:py-[1] py-[5px] px-[19px]`}>
                        {
                            otherappid.map((item) => {
                                return (
                                    <>
                                        <div onClick={() => openinstamodal()} className={`w-auto cursor-pointer ${item.appname == 'instagram' ? 'bg-purple-400 text-white font-[400]' : null} ${item.appname == 'Whatsapp' ? 'bg-green-400 text-white font-[400]' : null} ${item.appname == 'messenger' ? 'bg-blue-700 text-white font-[400]' : null}cursor-pointer gap-[10px] bg h-auto py-[5px] shadow-md rounded-md flex items-center justify-start px-[10px]`}>
                                            <span className={`w-auto text-white h-auto flex items-center justify-center`}>
                                                {item.icon}
                                            </span>
                                            <p className={`w-auto sm:hidden text-[15px] m-0`}>
                                                {item.appname}
                                            </p>
                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                    {/* ---------------------dashboard row-------------------------------------- */}
                    <div className={`w-full h-auto flex items-start justify-center sm:items-center bg-[#f8f9fa] flex-col`}>
                        <div className={`w-full flex items-center justify-between py-[15px] sm:w-screen px-[10px] h-auto`}>
                            <h2 className={`w-auto sm:m-0 h-auto text-[16px] sm:px-[20px] font-[600] flex items-center justify-start`}>Campaigns Details</h2>
                            <button onClick={HandleCampaingdelete} className={`w-auto h-auto pt-[4px] pb-[4px] mr-[20px] border-none text-gray-500 hover:text-red-500 bg-transparent`}>
                                Clear all campaign</button>
                        </div>
                        <div style={{ overflowY: 'scroll', height: 'auto', maxHeight: "350px", scrollbarWidth: "thin" }} className={`w-full sm:px-[20px] sm:overflow-scroll sm:w-[80%] py-[0px] relative overflow-y-scroll px-[16px] h-auto max-h-max`}>
                            <table className={`w-full h-full `}>
                                <thead className={`bg-[#2e498c] text-black sticky top-0`}>
                                    <tr>
                                        <th style={{ border: '1px solid #d9d9d9' }} className={` border sm:p-[3px] border-spacing-1 text-white border-[#d9d9d9]`}>S.No</th>
                                        <th style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 sm:p-[3px] text-white  border-[#d9d9d9]'>Date</th>
                                        <th style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 sm:p-[3px]  text-white border-[#d9d9d9]'>Campaign_Id</th>
                                        <th style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 sm:p-[3px] text-white border-[#d9d9d9]'>Campaing_Name</th>
                                        <th style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 sm:p-[3px] border-[#d9d9d9] text-white'>Owner Name</th>
                                        {/* <th className=' border border-spacing-1 border-[#d9d9d9]'>Template Name</th> */}
                                        <th style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 sm:p-[3px] border-[#d9d9d9] text-white'>Status</th>
                                        {/* <th className=' border border-spacing-1 border-[#d9d9d9]'>Contacts</th> */}
                                        {/* <th className=' border border-spacing-1 border-[#d9d9d9]'>Sent</th> */}
                                        {/* <th style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 sm:p-[3px] border-[#d9d9d9] text-white'>Details</th> */}
                                        <th style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 sm:p-[3px] border-[#d9d9d9] text-white'>Report</th>
                                        <th style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 sm:p-[3px] border-[#d9d9d9] text-white'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tabeldata.map((item, index) => (
                                            <tr className={``} key={index}>
                                                <td style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 border-[#d9d9d9]'>{index + 1}</td>
                                                <td style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 border-[#d9d9d9]'>{item.Date}</td>
                                                <td style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 border-[#d9d9d9]'>{item.Campaing_ID}</td>
                                                <td style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 border-[#d9d9d9]'>{item.Campaing_Name}</td>
                                                <td style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 border-[#d9d9d9]'>{item.Owner_Name}</td>
                                                {/* <td className=' border border-spacing-1 border-[#d9d9d9]'>{item.Template_Name}</td> */}
                                                <td className=' border border-spacing-1 border-[#d9d9d9] flex gap-1 items-center' style={{ border: '1px solid #d9d9d9', color: `${item.Status == 'accepted' ? 'green' : 'red'}` }}>
                                                    {item.Status}
                                                    {item.Status == 'accepted' ? <Icon icon="fa:send-o" style={{ color: "green" }} /> : <Icon icon="ic:round-sms-failed" style={{ color: "red" }} />}
                                                </td>
                                                {/* <td className=' border border-spacing-1 border-[#d9d9d9]'>{item.Contacts}</td> */}
                                                {/* <td className=' border border-spacing-1 border-[#d9d9d9]'>{item.Sent}</td> */}
                                                {/* <td style={{ border: '1px solid #d9d9d9' }} onClick={() => handlecampaignfill(item.Campaing_ID)} className=' border border-spacing-1 border-[#d9d9d9] cursor-pointer text-yellow-700'>Show</td> */}
                                                <td style={{ border: '1px solid #d9d9d9' }} onClick={() => openModalforreport(item)} className=' border border-spacing-1 border-[#d9d9d9] text-blue-400 hover:border-b cursor-pointer hover:border-b-blue-400'>{item.Report}</td>
                                                <td style={{ border: '1px solid #d9d9d9' }} className=' border border-spacing-1 border-[#d9d9d9] text-red-400 hover:border-b cursor-pointer hover:border-b-red-400'>

                                                    <p onClick={() => deletehandler(item.ID)} className={`m-0`}>
                                                        Delete
                                                    </p>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >

            {
                modalforreport && (
                    <Report setmodalreport={setmodalreport} id={reportdata} />
                )
            }


            {
                openinsta && <CreateCompaignModal closeWindow={setopeninsta} />
            }

        </>
    )
}

export default Dashboard
